@import '../_styles/constants';

.tasks {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 1.2rem;
  height: calc(100% - #{$planning-toolbar-height} - #{$planning-wrapper-padding-vertical});

  &:after {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    bottom: 0;
    width: $grid-gradient-width;
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    transition: 0.2s ease-in opacity;
    opacity: 1;
    pointer-events: none;
    z-index: 7;
  }

  &.no-gradient:after {
    opacity: 0;
  }
}
