@import '../../../_styles/colors';

.change-shift-modal {
  h1 {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.3rem;
  }

  h2 {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: $grey;
  }

  .shift-times-wrapper {
    display: flex;
    margin-bottom: 2.4rem;

    .input-wrapper {
      width: 10.4rem;
      .ui.input {
        margin-right: 2.4rem;
        width: 8rem;
      }
    }
  }

  .error-message {
    margin-top: 1.6rem;
  }
}
