.timeline-task {
  height: 8rem;
  font-size: 1.2rem;
  font-weight: 500;
  display: inline-flex;
  flex-direction: column;
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  min-width: 2rem;
}
.timeline-task .content {
  border: 1px solid #edf3f6;
  background-color: #f6f9fa;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  padding: 0.8rem;
  flex: 1;
  position: relative;
  z-index: 1;
}
.timeline-task .content .title-wrapper {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
}
.timeline-task .content .title-wrapper .title {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.timeline-task .content .title-wrapper .title.delayed {
  color: #e45f55;
}
.timeline-task .content .issues {
  width: 1.6rem;
  height: 1.6rem;
  margin-top: 0.2rem;
  text-align: center;
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: 700;
  border-radius: 50%;
  color: #ffffff;
  background: #e45f55;
}
.timeline-task.no-time .title, .timeline-task.reduced-time .title {
  display: flex;
  align-items: center;
}
.timeline-task.no-time .title > .icon, .timeline-task.reduced-time .title > .icon {
  margin-right: 0.2rem;
}
.timeline-task.no-time .title > .icon {
  color: #e45f55;
}
.timeline-task.reduced-time .title > .icon {
  color: #ed8f21;
}
.timeline-task.overflow-left .content,
.timeline-task.overflow-left .task-badge,
.timeline-task.overflow-left .task-badge.unplanned {
  padding-left: calc(20px + 4px);
}
.timeline-task .teams-info {
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(to left, #f6f9fa 0%, #f6f9fa 65%, rgba(246, 249, 250, 0) 100%);
  font-size: 1rem;
  color: #999fa3;
  padding: 0.8rem;
  padding-left: 2rem;
  text-align: right;
}
.timeline-task .teams-info .teams-count {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.timeline-task .teams-info .teams-count .icon {
  margin-left: 0.4rem;
}
.timeline-task .teams-info .main-team {
  display: block;
}
.timeline-task .flight-state {
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 1.1rem;
  text-transform: uppercase;
  color: #999fa3;
  padding-top: 0.2rem;
  display: block;
  white-space: nowrap;
}
.timeline-task .flight-state:before {
  content: "";
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 0.3rem;
  margin-right: 0.4rem;
  background-color: #999fa3;
}
.timeline-task .flight-state.in_air:before {
  background-color: #ebc43a;
}
.timeline-task .flight-state.landing:before {
  background-color: #ed8f21;
}
.timeline-task .flight-state.landed:before {
  background-color: #e45f55;
}
.timeline-task .flight-state.in_block:before {
  background-color: #47525a;
}
.timeline-task .flight-state.departing:before {
  background-color: #24c289;
}
.timeline-task .incomplete-alert {
  font-style: italic;
  color: #e45f55;
  margin-left: 0.1rem;
}
.timeline-task .task-badge {
  margin-top: -0.4rem;
  position: relative;
  z-index: 1;
}
.timeline-task .task-badge.unplanned {
  font-weight: 400;
  padding-left: 0.8rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #edf3f6;
  border-top: 0;
}
.timeline-task.unplanned {
  height: 6rem;
}
.timeline-task.unplanned .task-badge {
  font-size: 1rem;
}
.timeline-task.unplanned .content {
  padding-top: 0.6rem;
  padding-bottom: 0.4rem;
}
.timeline-task .time-window {
  position: absolute;
  height: 100%;
  left: 0;
  border: 1px solid #ebeced;
  border-radius: 0.4rem;
  opacity: 0;
  z-index: 0;
  max-width: 0;
}
.timeline-task:hover .time-window, .timeline-task.selected .time-window {
  opacity: 1;
  max-width: unset;
  z-index: 2;
}
.timeline-task:hover .content,
.timeline-task:hover .task-badge, .timeline-task.selected .content,
.timeline-task.selected .task-badge {
  z-index: 3;
}
.timeline-task.selected-team .content {
  background-color: #dee8ed;
}
.timeline-task.selected-team .teams-info {
  background: linear-gradient(to left, #dce8ee 0%, #dce8ee 65%, rgba(220, 232, 238, 0) 100%);
}
.timeline-task.selected-team.unplanned .task-badge {
  background-color: #dce8ee;
}
.timeline-task.selected-task .content {
  border: 1px solid #77a4b8;
}
.timeline-task.selected-task.unplanned .task-badge {
  border: 1px solid #77a4b8;
  border-top: 0;
}