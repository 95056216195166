@import '../../_styles/colors';

.sidebar-footer {
  flex: 0;
  display: flex;
  padding: 2.4rem 3.2rem;
  border-top: 1px solid $grey-light;
  justify-content: flex-end;
  button,
  .ui.button {
    margin-right: 1.4rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
}
