.reports-log {
  padding: 2rem;
  overflow-x: scroll;
  min-height: calc(100vh - 6.4rem);
}
.reports-log .filter-header {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
}
.reports-log .filter-header > :not(:first-child) {
  margin-left: 2rem;
}
.reports-log .filter-header > div {
  display: flex;
  align-items: center;
}
.reports-log .filter-header > div > :not(:first-child) {
  margin-left: 0.8rem;
}
.reports-log .filter-header .input-label {
  font-size: 1.4rem;
  color: #999fa3;
}
.reports-log .filter-header input {
  width: 16rem;
}
.reports-log .ui.table {
  min-width: 160rem;
}
.reports-log .ui.table th,
.reports-log .ui.table td {
  padding: 1.5rem 1rem;
}
.reports-log .ui.table td {
  white-space: pre;
}
.reports-log .ui.table td.description-cell {
  white-space: normal;
}
.reports-log .ui.table td.state-cell {
  display: flex;
  justify-content: center;
}
.reports-log .ui.table th:nth-child(1) {
  width: 15rem;
}
.reports-log .ui.table th:nth-child(2) {
  width: 15rem;
}
.reports-log .ui.table th:nth-child(3) {
  width: 25rem;
}
.reports-log .ui.table th:nth-child(4) {
  width: 15rem;
}
.reports-log .ui.table th:nth-child(5) {
  width: 15rem;
}
.reports-log .ui.table th:nth-child(6) {
  width: 15rem;
}
.reports-log .ui.table th:nth-child(7) {
  align-items: center;
  width: 15rem;
}
.reports-log .ui.table th:nth-child(8) {
  width: auto;
}
.reports-log .ui.table .over-time-cell.positive {
  color: #e45f55;
  font-weight: 700;
}
.reports-log .no-data {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  padding-top: 6.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.4rem;
  text-align: center;
}
.reports-log .no-data :first-child {
  text-transform: uppercase;
  font-weight: bold;
}
.reports-log .no-data :not(:first-child) {
  padding-top: 1rem;
}