/* This file overwrites the default css from the npm package */
.react-datepicker-wrapper {
  display: block;
}

.react-datepicker__input-container {
  display: block;
  position: static;
}

.react-datepicker-popper {
  z-index: 1000;
}
.react-datepicker-popper[data-placement^=bottom], .react-datepicker-popper[data-placement^=top] {
  margin-top: 1rem;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  border-bottom-color: #4986a0;
  border-top-color: #4986a0;
  left: 4rem;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle:before {
  border: 0;
}
.react-datepicker-popper[data-placement^=top] {
  margin-bottom: 7.5rem;
}

.react-datepicker {
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  background-color: #4986a0;
  box-shadow: 0.8rem 0.8rem 5.4rem 0 rgba(0, 0, 0, 0.15);
  font-weight: normal;
  border: 1px solid #4986a0;
  border-radius: 2rem;
  margin-top: 0.2rem;
}
.react-datepicker .react-datepicker__header {
  background-color: transparent;
  border: 0;
  padding-top: 0;
  padding-bottom: 1rem;
}
.react-datepicker .react-datepicker__navigation--previous,
.react-datepicker .react-datepicker__navigation--next {
  border: 0;
  background-size: contain;
  background-repeat: no-repeat;
  width: 2.4rem;
  height: 2.4rem;
  top: 1.6rem;
}
.react-datepicker .react-datepicker__navigation--previous {
  background-image: url("../../_assets/svg/chevron_left.svg");
  left: 2rem;
}
.react-datepicker .react-datepicker__navigation--next {
  background-image: url("../../_assets/svg/chevron_right.svg");
  right: 2rem;
}
.react-datepicker .react-datepicker__month {
  margin: 0;
  padding: 0 1.6rem;
  padding-bottom: 1.9rem;
}
.react-datepicker .react-datepicker__current-month {
  padding: 1.6rem 0;
  font-size: 1.6rem;
  color: #edf3f6;
  font-weight: 700;
  text-transform: capitalize;
}
.react-datepicker .react-datepicker__day {
  height: 3.25rem;
  width: 3.25rem;
  line-height: 3.25rem;
  margin: 0.3rem;
  color: #edf3f6;
  border-radius: 50%;
}
.react-datepicker .react-datepicker__day-name {
  width: 3.25rem;
  line-height: 1.2rem;
  margin: 0.3rem;
  color: #98bacc;
  font-weight: 700;
  text-transform: capitalize;
}
.react-datepicker .react-datepicker__day--today {
  background-color: #a9c6d4;
  color: #4986a0;
}
.react-datepicker .react-datepicker__day:hover,
.react-datepicker .react-datepicker__day--selected {
  background-color: #edf3f6;
  color: #4986a0;
  font-weight: 700;
}
.react-datepicker .react-datepicker__day--outside-month {
  color: #98bacc;
}
.react-datepicker .react-datepicker__day--disabled {
  color: #98bacc;
  pointer-events: none;
}