@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slide-in-right {
  from {
    right: -1rem;
    opacity: 0;
  }
  to {
    right: 0rem;
    opacity: 1;
  }
}
