.tasks {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 1.2rem;
  height: calc(100% - 4rem - 1.2rem);
}
.tasks:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  bottom: 0;
  width: 4rem;
  background: linear-gradient(to left, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
  transition: 0.2s ease-in opacity;
  opacity: 1;
  pointer-events: none;
  z-index: 7;
}
.tasks.no-gradient:after {
  opacity: 0;
}