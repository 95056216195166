.semantic-button-content,
.text-link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.semantic-button-content :not(:first-child),
.text-link :not(:first-child) {
  margin-left: 0.8rem;
}

.text-link {
  position: relative;
  background: transparent;
  border: none;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.2rem;
  text-align: center;
  color: #999fa3;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
}
.text-link:hover, .text-link:focus {
  outline: transparent;
  color: #47525a;
}
.text-link.negative {
  color: #e45f55;
}
.text-link.negative:hover, .text-link.negative:focus {
  color: #dd3629;
}
.text-link.back-navigation {
  font-size: 1.2rem;
  font-weight: 400;
  color: #4986a0;
}
.text-link.back-navigation .icon {
  color: #47525a;
}
.text-link.back-navigation:hover, .text-link.back-navigation:focus {
  color: #39697d;
}
.text-link.back-navigation:hover .icon, .text-link.back-navigation:focus .icon {
  color: #1a1e21;
}
.text-link.loading {
  color: transparent;
}
.text-link.loading:hover, .text-link.loading:focus {
  color: transparent;
}
.text-link > .ui.active.inline.loader {
  position: absolute;
  width: 100%;
}