@import '../../_styles/colors';
@import '../../_styles/animations';

.team-info {
  position: relative;
  text-align: left;
  display: flex;
  justify-content: space-between;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .name {
    font-size: 1.6rem;
    font-weight: 700;
    margin: 0 1.5rem;
    padding-top: 1rem;
  }

  .shift {
    font-size: 1.2rem;
    color: $grey;
    margin: 0 0 1.1rem 1.5rem;
    white-space: nowrap;
  }
  .pda-user-loading {
    margin: 0 1.5rem;
  }
  .pda-user {
    display: flex;
    align-items: center;
    margin: 0 0.8rem 0 1.3rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0.4rem;
    color: $primary;

    & > p {
      font-size: 1.4rem;
      margin: 0 0 0 0.8rem;
      line-height: 2rem;
    }
  }

  .amount-of-members {
    height: 1.7rem;
    width: 1.7rem;
    position: absolute;
    top: 1.2rem;
    right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary;
    border-radius: 50%;
    text-align: center;
    font-weight: 700;
    font-size: 1.2rem;
    color: $white;
  }

  &.with-actions {
    .name {
      padding-top: 0;
    }
    .shift {
      margin-bottom: 0;

      &-date {
        display: block;
      }
    }

    .actions {
      position: absolute;
      right: 0;
      top: 0;
      display: none;
      flex-direction: column;
      justify-content: space-between;
      margin: 1.2rem 1rem;
      .team-edit,
      .team-lock {
        color: $blue;
      }
      .team-remove {
        color: $red;
      }
      > :not(:first-child) {
        margin-top: 0.75rem;
      }
    }

    &:hover,
    &:focus {
      & .amount-of-members {
        display: none;
      }
      & .actions {
        display: flex;
        animation-name: slide-in-right;
        animation-duration: 0.2s;
        animation-timing-function: ease-in;
      }
    }
  }
}
