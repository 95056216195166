@import '../_styles/constants';
@import '../_styles/colors';

.sidebar {
  position: fixed;
  top: $navbar-height;
  right: 0;
  bottom: 0;
  width: 40rem;
  background-color: $white;
  z-index: 10;
  box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.22);
  transform: translateX(45rem);
  transition: all 0.2s ease-in;
  &.open {
    transform: translateX(0);
  }
  > .icon-button {
    position: absolute;
    color: $grey-dark;
    top: 1.6rem;
    right: 2rem;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    width: 2.4rem;
    height: 2.4rem;
    padding: 0;
    outline: 0;
  }
}

.sidebar-header-buttons {
  display: flex;
  position: absolute;
  top: 1.6rem;

  &.left {
    left: 2.5rem;
  }

  &.right {
    right: 4.4rem;
  }

  .icon-button {
    margin: 0 0.8rem;
    .icon {
      color: $grey-dark;
    }
  }
}

.sidebar-inner {
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.sidebar-content {
  overflow-y: auto;
}
