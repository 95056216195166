.edit-team-modal .input-date-wrapper {
  margin-top: 1.6rem;
  width: 20rem;
}
.edit-team-modal .error-message {
  margin-top: 1.6rem;
}
.edit-team-modal .input-requirement {
  color: #999fa3;
  margin-top: 0.5rem;
  font-size: 1.4rem;
  font-style: italic;
  text-align: right;
}