.login-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  .error-message {
    padding-bottom: 3rem;
  }
  .logo {
    margin-top: 6.8rem;
    margin-bottom: 14rem;
    width: 30rem;
    height: auto;
  }
  form {
    text-align: center;
  }

  .input-wrapper {
    text-align: center;
    margin: 5rem 0 3rem;
    input {
      min-width: 37rem;
    }
  }
}
