@import '../../_styles/constants';
@import '../../_styles/colors';

.main-menu {
  z-index: 10;
  background-color: $blue-dark;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $navbar-height;
  display: flex;
  aside {
    background-color: $white-transparent;
    height: 100%;
    padding: 1.2rem 2rem;
    margin-right: 1rem;
  }
  a {
    position: relative;
    color: $white;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    opacity: 0.5;
    padding: 2.2rem 2.4rem;
    &:hover {
      opacity: 0.8;
    }
    &.active {
      opacity: 1;
      &:after {
        content: '';
        height: 2px;

        position: absolute;
        bottom: 1.4rem;
        left: 2.5rem;
        right: 2.5rem;
        background-color: $white;
      }
    }
  }
}
