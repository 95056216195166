.task-edit .sidebar-content {
  padding: 0 3.2rem;
}
.task-edit .row {
  display: flex;
}
.task-edit .row:not(:first-child) {
  margin-top: 1.8rem;
}
.task-edit .row > div {
  flex: 1;
}
.task-edit .row > div:not(:first-child) {
  margin-left: 2.4rem;
}
.task-edit .divider {
  margin: 2.5rem 0;
  width: 100%;
  height: 1px;
  background-color: #ebeced;
}
.task-edit .error-message {
  margin-top: 1rem;
}
.task-edit .sidebar-footer {
  margin-top: 3.2rem;
}