.login-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.login-container .error-message {
  padding-bottom: 3rem;
}
.login-container .logo {
  margin-top: 6.8rem;
  margin-bottom: 14rem;
  width: 30rem;
  height: auto;
}
.login-container form {
  text-align: center;
}
.login-container .input-wrapper {
  text-align: center;
  margin: 5rem 0 3rem;
}
.login-container .input-wrapper input {
  min-width: 37rem;
}