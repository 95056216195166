.icon-button {
  border: 0;
  background-color: transparent;
  text-align: center;
  vertical-align: middle;
  padding: 0;
  cursor: pointer;
  outline: 0;
}

i.icon {
  display: inline-block;
  margin: 0;
}
i.icon svg {
  overflow: visible;
}
i.icon svg path {
  fill: currentColor;
}