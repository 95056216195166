@import '../../_styles/colors';
@import '../../_styles/animations';

$teamMemberWrapperWidth: 18.4rem;

.team-member-info {
  overflow: hidden;
  position: relative;
  text-align: left;
  background-color: $grey-bright;
  border-radius: 0.4rem;
  border: 1px solid $blue-light;
  height: 100%;
  width: $teamMemberWrapperWidth;

  .name {
    font-size: 1.4rem;
    font-weight: 500;
    margin: 0 1.5rem;
    padding-top: 1rem;
  }

  .shift {
    margin-left: 1.5rem;
    padding: 0;
    font-size: 1.2rem;
    text-align: left;
    line-height: 1.6rem;
    border: none;
    color: $grey;
    background-color: $grey-bright;

    & > span:last-of-type {
      display: block;
    }

    &:hover,
    &:focus {
      cursor: pointer;
      outline: transparent;
      color: darken($grey, 20);
    }
  }

  &:hover,
  &:focus {
    border: 1px solid $blue;
    outline: transparent;
  }

  .delete-action-wrapper {
    display: none;
    position: absolute;
    height: 100%;
    right: 0.5rem;
    top: 0;
    .icon-button {
      position: absolute;
      right: 0;
      top: calc(50% - 0.8rem);
      color: $red;
    }
  }
  &:hover,
  &:focus {
    .delete-action-wrapper {
      display: block;
      .icon-button {
        animation-name: slide-in-right;
        animation-duration: 0.2s;
        animation-timing-function: ease-in;
      }
    }
  }

  &.shift-overlap {
    border-color: $red;

    .shift > span:first-of-type {
      color: $red;
      font-weight: 500;
    }

    .overlap-icon {
      bottom: 0.5rem;
      right: 0.5rem;
      position: absolute;
      color: $red;
    }
  }
}
