@import '../../_styles/colors';

.nightStopTooltip {
  background-color: $white;
  text-align: center;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;

  position: absolute;
  z-index: 1;
  top: -0.5rem;
  left: 100%;
}

.nightStopIcon {
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  margin-left: -2px;
  margin-top: 2px;

  &[aria-label]:after {
    content: attr(aria-label);
    position: absolute;
    left: 100%;
    bottom: 100%;
    width: max-content;
    background-color: $white;
    text-align: center;
    padding: 0.5rem 0.75rem;
    border-radius: 0.5rem;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &.isFirst[aria-label]::after {
    bottom: auto;
    top: 0;
  }

  &[aria-label]:hover:after {
    opacity: 1;
  }
}
