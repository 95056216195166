@import '../../_styles/colors';
@import '../../_styles/animations';

.employees-search {
  position: relative;

  & > .ui.input {
    & > input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding-right: 3.6rem !important; /* Semantic override was needed */
    }

    & > i.icon {
      top: 1.1rem;
      left: 0.8rem;
    }
  }

  > .icon-button {
    position: absolute;
    top: 1rem;
    right: 1.4rem;
    color: $red;
    animation-name: fade-in;
    animation-duration: 0.3s;

    &:hover,
    &:focus {
      color: darken($red, 10);
      transform: scale(1.05);
    }

    path:last-of-type {
      fill: $white;
    }
  }

  .employees-wrapper {
    display: flex;
    flex-direction: column;
    height: 23rem;
    border: 1px solid $grey-light;
    border-top: 0;
    border-radius: 0 0 0.8rem 0.8rem;
    overflow-y: auto;

    & > button {
      display: flex;
      justify-content: space-between;
      min-height: 3.6rem;
      padding: 0.8rem 5.2rem 0.8rem 1rem;
      border: none;
      background-color: $white;
      color: #47525a;
      font-size: 14px;
      line-height: 20px;

      &:hover,
      &:focus {
        color: $blue;
        background-color: $grey-bright;
        outline: transparent;
        cursor: pointer;
      }
    }

    .no-employees {
      font-weight: 700;
      font-size: 1.2rem;
      text-transform: uppercase;
      text-align: center;
    }

    > span {
      margin: 1.6rem;
    }
  }
}
