@import '../../_styles/colors';

.task-badge {
  &.accepted {
    background-color: $yellow;
    color: $text-color;
  }
  &.planned {
    background: repeating-linear-gradient(
      -45deg,
      darken($grey-light, 6),
      darken($grey-light, 6) 1px,
      $grey-light 1px,
      $grey-light 8px
    );
    color: $grey-dark;
  }
  &.accepted.no-time,
  &.planned.no-time {
    background: $red;
    color: $white;
  }
  &.accepted.reduced-time,
  &.planned.reduced-time {
    background: $orange;
    color: $white;
  }
  &.arrived {
    background-color: $grey-dark;
    color: $white;
  }
  &.ended {
    background-color: $green;
    color: $white;
  }
  &.started {
    background-color: $blue-royal;
    color: $white;
  }
  &.unplanned {
    background-color: $grey-bright;
    color: $grey-dark;
  }
  &.cancelled {
    background-color: $grey-dark;
    color: $white;
  }
}

.badge-divider {
  position: relative;
  padding-left: 3.2rem;
  &:before {
    left: 0;
    height: 1px;
    background-color: $grey-light;
    width: 100%;
    content: '';
    position: absolute;
    top: 50%;
  }
  .badge {
    position: relative;
  }
}
