.planning-date-picker {
  display: flex;
  align-items: center;
  font-weight: 700;
}
.planning-date-picker * {
  color: #47525a;
}
.planning-date-picker .datepicker-trigger {
  display: block;
  width: 20rem;
  text-align: center;
}
.planning-date-picker .datepicker-trigger button {
  width: 100%;
}
.planning-date-picker .react-datepicker__triangle {
  display: none;
}
.planning-date-picker .react-datepicker-popper[data-placement^=bottom] {
  margin-top: 0.5rem;
}