@import '../../_styles/colors';

.task-edit {
  .sidebar-content {
    padding: 0 3.2rem;
  }
  .row {
    display: flex;

    &:not(:first-child) {
      margin-top: 1.8rem;
    }

    > div {
      flex: 1;

      &:not(:first-child) {
        margin-left: 2.4rem;
      }
    }
  }
  .divider {
    margin: 2.5rem 0;
    width: 100%;
    height: 1px;
    background-color: $grey-light;
  }
  .error-message {
    margin-top: 1rem;
  }
  .sidebar-footer {
    margin-top: 3.2rem;
  }
}
