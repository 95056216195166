@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes slide-in-right {
  from {
    right: -1rem;
    opacity: 0;
  }
  to {
    right: 0rem;
    opacity: 1;
  }
}
.team-info {
  position: relative;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.team-info > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.team-info .name {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0 1.5rem;
  padding-top: 1rem;
}
.team-info .shift {
  font-size: 1.2rem;
  color: #999fa3;
  margin: 0 0 1.1rem 1.5rem;
  white-space: nowrap;
}
.team-info .pda-user-loading {
  margin: 0 1.5rem;
}
.team-info .pda-user {
  display: flex;
  align-items: center;
  margin: 0 0.8rem 0 1.3rem;
  padding: 0.2rem 0.5rem;
  border-radius: 0.4rem;
  color: #4986a0;
}
.team-info .pda-user > p {
  font-size: 1.4rem;
  margin: 0 0 0 0.8rem;
  line-height: 2rem;
}
.team-info .amount-of-members {
  height: 1.7rem;
  width: 1.7rem;
  position: absolute;
  top: 1.2rem;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4986a0;
  border-radius: 50%;
  text-align: center;
  font-weight: 700;
  font-size: 1.2rem;
  color: #ffffff;
}
.team-info.with-actions .name {
  padding-top: 0;
}
.team-info.with-actions .shift {
  margin-bottom: 0;
}
.team-info.with-actions .shift-date {
  display: block;
}
.team-info.with-actions .actions {
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  margin: 1.2rem 1rem;
}
.team-info.with-actions .actions .team-edit,
.team-info.with-actions .actions .team-lock {
  color: #4986a0;
}
.team-info.with-actions .actions .team-remove {
  color: #e45f55;
}
.team-info.with-actions .actions > :not(:first-child) {
  margin-top: 0.75rem;
}
.team-info.with-actions:hover .amount-of-members, .team-info.with-actions:focus .amount-of-members {
  display: none;
}
.team-info.with-actions:hover .actions, .team-info.with-actions:focus .actions {
  display: flex;
  animation-name: slide-in-right;
  animation-duration: 0.2s;
  animation-timing-function: ease-in;
}