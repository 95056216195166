@import '../_styles/colors';
@import '../_styles/constants';

.reports-log {
  padding: 2rem;
  overflow-x: scroll;
  min-height: calc(100vh - #{$navbar-height});

  .filter-header {
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    > :not(:first-child) {
      margin-left: 2rem;
    }
    > div {
      display: flex;
      align-items: center;
      > :not(:first-child) {
        margin-left: 0.8rem;
      }
    }
    .input-label {
      font-size: 1.4rem;
      color: $grey;
    }
    input {
      width: 16rem;
    }
  }

  .ui.table {
    min-width: 160rem;
    th,
    td {
      padding: 1.5rem 1rem;
    }
    td {
      white-space: pre;
      &.description-cell {
        white-space: normal;
      }
      &.state-cell {
        display: flex;
        justify-content: center;
      }
    }
    th {
      &:nth-child(1) {
        width: 15rem;
      }
      &:nth-child(2) {
        width: 15rem;
      }
      &:nth-child(3) {
        width: 25rem;
      }
      &:nth-child(4) {
        width: 15rem;
      }
      &:nth-child(5) {
        width: 15rem;
      }
      &:nth-child(6) {
        width: 15rem;
      }

      &:nth-child(7) {
        align-items: center;
        width: 15rem;
      }
      &:nth-child(8) {
        width: auto;
      }
    }

    .over-time-cell.positive {
      color: $red;
      font-weight: 700;
    }
  }

  .no-data {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    padding-top: $navbar-height;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 1.4rem;
    text-align: center;
    :first-child {
      text-transform: uppercase;
      font-weight: bold;
    }
    :not(:first-child) {
      padding-top: 1rem;
    }
  }
}
