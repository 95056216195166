.add-team-member-button {
  background-color: white;
  color: #4986a0;
  border: 1px dashed #4986a0;
  box-sizing: border-box;
  border-radius: 0.4rem;
  width: 18.4rem;
  height: 100%;
  font-weight: 500;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-team-member-button > span {
  margin-left: 0.8rem;
}
.add-team-member-button:focus, .add-team-member-button:hover {
  border: 1px dashed #39697d;
  color: #39697d;
  cursor: pointer;
  outline: transparent;
}
.add-team-member-button:focus i, .add-team-member-button:hover i {
  transform: scale(1.05);
}

.add-team-member-modal .error-message {
  margin-top: 1.6rem;
}