.panel-container {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.panel-container .resizer-container {
  width: calc(1075.2rem + 190px);
  height: 1.2rem;
  margin: 0.5rem 0;
  z-index: 9;
}
.panel-container .resizer {
  position: sticky;
  cursor: row-resize;
  width: 100vw;
  user-select: none;
  left: 0;
  height: 100%;
}
.panel-container .resizer::after,
.panel-container .resizer::before {
  content: "";
  border-top: 1px solid #999fa3;
  position: absolute;
  left: 50%;
  transform: translateX(-100%);
  bottom: 0;
  display: inline-block;
  width: 2rem;
  margin: 0.3rem 0;
}
.panel-container .resizer::before {
  top: 0;
}