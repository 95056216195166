@import '../../_styles/colors';
@import '../../_styles/constants';

$color-highlight: darken($blue-light, 5);

.timeline-task {
  height: 8rem;
  font-size: 1.2rem;
  font-weight: 500;
  display: inline-flex;
  flex-direction: column;
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  min-width: 2rem;

  .content {
    border: 1px solid $blue-light;
    background-color: $grey-bright;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    padding: 0.8rem;
    flex: 1;
    position: relative;
    z-index: 1;

    .title-wrapper {
      display: inline-flex;
      align-items: center;
      max-width: 100%;

      .title {
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;

        &.delayed {
          color: $red;
        }
      }
    }

    .issues {
      width: 1.6rem;
      height: 1.6rem;
      margin-top: 0.2rem;
      text-align: center;
      font-size: 1rem;
      line-height: 1.6rem;
      font-weight: 700;
      border-radius: 50%;
      color: $white;
      background: $red;
    }
  }

  &.no-time .title,
  &.reduced-time .title {
    display: flex;
    align-items: center;

    & > .icon {
      margin-right: 0.2rem;
    }
  }

  &.no-time .title > .icon {
    color: $red;
  }

  &.reduced-time .title > .icon {
    color: $orange;
  }

  &.overflow-left {
    .content,
    .task-badge,
    .task-badge.unplanned {
      padding-left: $task-overflow-padding;
    }
  }

  .teams-info {
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(to left, $grey-bright 0%, $grey-bright 65%, transparentize($grey-bright, 1) 100%);
    font-size: 1rem;
    color: $grey;
    padding: 0.8rem;
    padding-left: 2rem;
    text-align: right;

    .teams-count {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      .icon {
        margin-left: 0.4rem;
      }
    }
    .main-team {
      display: block;
    }
  }

  .flight-state {
    font-weight: 700;
    font-size: 0.9rem;
    line-height: 1.1rem;
    text-transform: uppercase;
    color: $grey;
    padding-top: 0.2rem;
    display: block;
    white-space: nowrap;
    &:before {
      content: '';
      display: inline-block;
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 0.3rem;
      margin-right: 0.4rem;
      background-color: $grey;
    }
    &.in_air:before {
      background-color: $yellow;
    }
    &.landing:before {
      background-color: $orange;
    }
    &.landed:before {
      background-color: $red;
    }
    &.in_block:before {
      background-color: $grey-dark;
    }
    &.departing:before {
      background-color: $green;
    }
  }

  .incomplete-alert {
    font-style: italic;
    color: $red;
    margin-left: 0.1rem;
  }

  .task-badge {
    margin-top: -0.4rem;
    &.unplanned {
      font-weight: 400;
      padding-left: 0.8rem;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border: 1px solid $blue-light;
      border-top: 0;
    }
    position: relative;
    z-index: 1;
  }

  &.unplanned {
    height: 6rem;
    .task-badge {
      font-size: 1rem;
    }
    .content {
      padding-top: 0.6rem;
      padding-bottom: 0.4rem;
    }
  }

  .time-window {
    position: absolute;
    height: 100%;
    left: 0;
    border: 1px solid $grey-light;
    border-radius: 0.4rem;
    opacity: 0;
    z-index: 0;
    max-width: 0;
  }

  &:hover,
  &.selected {
    .time-window {
      opacity: 1;
      max-width: unset;
      z-index: 2;
    }
    .content,
    .task-badge {
      z-index: 3;
    }
  }

  &.selected-team {
    .content {
      background-color: $cyan-blue;
    }

    .teams-info {
      background: linear-gradient(to left, $color-highlight 0%, $color-highlight 65%, transparentize($color-highlight, 1) 100%);
    }
    &.unplanned .task-badge {
      background-color: $color-highlight;
    }
  }

  &.selected-task {
    .content {
      border: 1px solid $cyan;
    }
    &.unplanned .task-badge {
      border: 1px solid $cyan;
      border-top: 0;
    }
  }
}
