.plan-task-modal {
  .content {
    .dropdown {
      margin: 0.8rem 0 1.2rem 0;
    }
  }
  .row {
    display: flex;
    margin-top: 1.8rem;
    > div {
      flex: 1;

      &:not(:first-child) {
        margin-left: 2.4rem;
      }
    }
  }
}
