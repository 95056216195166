@import '../../../_styles/colors';

.edit-team-modal {
  .input-date-wrapper {
    margin-top: 1.6rem;
    width: 20rem;
  }
  .error-message {
    margin-top: 1.6rem;
  }
  .input-requirement {
    color: $grey;
    margin-top: 0.5rem;
    font-size: 1.4rem;
    font-style: italic;
    text-align: right;
  }
}
