.sidebar {
  position: fixed;
  top: 6.4rem;
  right: 0;
  bottom: 0;
  width: 40rem;
  background-color: #ffffff;
  z-index: 10;
  box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.22);
  transform: translateX(45rem);
  transition: all 0.2s ease-in;
}
.sidebar.open {
  transform: translateX(0);
}
.sidebar > .icon-button {
  position: absolute;
  color: #47525a;
  top: 1.6rem;
  right: 2rem;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  width: 2.4rem;
  height: 2.4rem;
  padding: 0;
  outline: 0;
}

.sidebar-header-buttons {
  display: flex;
  position: absolute;
  top: 1.6rem;
}
.sidebar-header-buttons.left {
  left: 2.5rem;
}
.sidebar-header-buttons.right {
  right: 4.4rem;
}
.sidebar-header-buttons .icon-button {
  margin: 0 0.8rem;
}
.sidebar-header-buttons .icon-button .icon {
  color: #47525a;
}

.sidebar-inner {
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.sidebar-content {
  overflow-y: auto;
}