@import '../../_styles/colors';

.badge {
  display: inline-flex;
  align-items: center;
  background-color: $grey-light;
  color: $grey;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0.3rem 0.4rem;
  text-transform: uppercase;
  border-radius: 0.4rem;
  line-height: 1.4rem;
  height: 2rem;
  white-space: nowrap;
  overflow: hidden;
  .icon {
    margin-right: 0.6rem;
  }
}
