.base-modal .close-icon {
  right: 2rem;
  top: 2rem;
  position: absolute;
  color: #999fa3;
}
.base-modal .actions {
  display: flex;
  justify-content: flex-end;
}
.base-modal form .header {
  padding: 3.4rem 3.2rem;
  font-size: 2rem;
  font-weight: 700;
}
.base-modal form .content {
  padding: 0 3.2rem;
}
.base-modal form .actions {
  padding: 4rem 3.2rem 2.4rem;
}