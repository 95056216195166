@import '../../_styles/colors';
@import '../../_styles/constants';

.button-group {
  display: inline-block;
  border-radius: 8px;
  box-shadow: 0px 8px 15px $shadow;
  button {
    cursor: pointer;
    font-family: $font-family;
    white-space: nowrap;
    color: $blue;
    background-color: $white;
    width: 12rem;
    height: 4rem;
    border: none;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2.2rem;
    text-align: center;
    border-radius: 8px;
    &:hover,
    &:focus {
      outline: transparent;
      color: darken($blue, 10);
    }
    &.negative {
      color: $red;
    }
  }
}

button.selected {
  background-color: $blue;
  color: $white;
  &:hover,
  &:focus {
    color: $white;
    background-color: darken($blue, 10);
  }
}
