@import '../../_styles/colors';

.task-issues {
  .sidebar-content {
    border-top: 1px solid $grey-light;
    border-bottom: 1px solid $grey-light;

    > .issue-item {
      &:after {
        content: '';
        height: 0.1rem;
        width: 100%;
        bottom: 0;
        left: 0;
        margin-left: 2rem;
        position: absolute;
        background: $grey-light;
      }

      &:last-of-type:after {
        content: none;
      }
    }
  }
}
