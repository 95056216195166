@import '../../_styles/colors';

.sidebar-header {
  padding: 4rem 3.2rem 2.4rem;
  & > div {
    flex: 0;
    display: flex;
    align-items: center;
    h1 {
      display: inline-block;
      margin-right: 0.8rem;
      font-size: 2rem;
      line-height: 2.3rem;
    }
    .badge {
      margin-right: 0.6rem;
    }
  }

  .sidebar-header-buttons.left ~ & {
    padding: 5.1rem 3.2rem 2.4rem;
  }
}
