@import '../../_styles/colors';
@import '../../_styles/constants';

.semantic-button-content,
.text-link {
  display: flex;
  align-items: center;
  justify-content: center;
  :not(:first-child) {
    margin-left: 0.8rem;
  }
}

.text-link {
  position: relative;
  background: transparent;
  border: none;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.2rem;
  text-align: center;
  color: $grey;
  cursor: pointer;
  font-family: $font-family;
  white-space: nowrap;

  &:hover,
  &:focus {
    outline: transparent;
    color: $grey-dark;
  }
  &.negative {
    color: $red;
    &:hover,
    &:focus {
      color: darken($red, 10);
    }
  }
  &.back-navigation {
    font-size: 1.2rem;
    font-weight: 400;
    color: $blue;
    .icon {
      color: $grey-dark;
    }

    &:hover,
    &:focus {
      color: darken($blue, 10);
      .icon {
        color: darken($grey-dark, 20);
      }
    }
  }
  &.loading {
    color: transparent;
    &:hover,
    &:focus {
      color: transparent;
    }
  }
  > .ui.active.inline.loader {
    position: absolute;
    width: 100%;
  }
}
