@import '../../_styles/colors';

.planning-date-picker {
  display: flex;
  align-items: center;
  font-weight: 700;
  * {
    color: $text-color;
  }

  .datepicker-trigger {
    display: block;
    width: 20rem;
    text-align: center;

    button {
      width: 100%;
    }
  }

  // TODO: We should be able to delete this if react-datepicker showPopperArrow prop is released
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 0.5rem;
  }
}
