@import '../../_styles/colors';
@import '../../_styles/constants';

$pda-column-width: 20rem;

.teams-grid {
  height: 100%;
  border: 1px solid $grey-light;
  border-radius: $grid-border-radius;

  .teams-grid-header {
    display: flex;

    .title-wrapper,
    .description-wrapper {
      display: flex;
      align-items: center;
      height: $time-indicator-height;
      position: relative;
      background-color: $grey-bright;

      span {
        font-weight: 700;
        font-size: 1.2rem;
        color: $blue-dark;
      }

      &:after {
        content: '';
        height: $time-indicator-ticks-height;
        position: absolute;
        bottom: 0;
        box-sizing: border-box;
        background-color: $blue-light;
        width: 100%;
      }
    }

    .title-wrapper {
      min-width: $grid-team-width;

      span {
        padding-left: 1.6rem;
      }
    }
    .description-wrapper {
      width: 100%;

      span {
        width: $pda-column-width;
        padding: 0 0.8rem;
      }
    }
  }

  .teams-grid-content {
    height: calc(100% - #{$time-indicator-height});
    overflow-y: scroll;
  }
}
