.teams-grid {
  height: 100%;
  border: 1px solid #ebeced;
  border-radius: 0.2rem;
}
.teams-grid .teams-grid-header {
  display: flex;
}
.teams-grid .teams-grid-header .title-wrapper,
.teams-grid .teams-grid-header .description-wrapper {
  display: flex;
  align-items: center;
  height: 4rem;
  position: relative;
  background-color: #f6f9fa;
}
.teams-grid .teams-grid-header .title-wrapper span,
.teams-grid .teams-grid-header .description-wrapper span {
  font-weight: 700;
  font-size: 1.2rem;
  color: #333f48;
}
.teams-grid .teams-grid-header .title-wrapper:after,
.teams-grid .teams-grid-header .description-wrapper:after {
  content: "";
  height: 0.4rem;
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  background-color: #edf3f6;
  width: 100%;
}
.teams-grid .teams-grid-header .title-wrapper {
  min-width: 190px;
}
.teams-grid .teams-grid-header .title-wrapper span {
  padding-left: 1.6rem;
}
.teams-grid .teams-grid-header .description-wrapper {
  width: 100%;
}
.teams-grid .teams-grid-header .description-wrapper span {
  width: 20rem;
  padding: 0 0.8rem;
}
.teams-grid .teams-grid-content {
  height: calc(100% - 4rem);
  overflow-y: scroll;
}