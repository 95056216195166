@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes slide-in-right {
  from {
    right: -1rem;
    opacity: 0;
  }
  to {
    right: 0rem;
    opacity: 1;
  }
}
.employees-search {
  position: relative;
}
.employees-search > .ui.input > input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 3.6rem !important; /* Semantic override was needed */
}
.employees-search > .ui.input > i.icon {
  top: 1.1rem;
  left: 0.8rem;
}
.employees-search > .icon-button {
  position: absolute;
  top: 1rem;
  right: 1.4rem;
  color: #e45f55;
  animation-name: fade-in;
  animation-duration: 0.3s;
}
.employees-search > .icon-button:hover, .employees-search > .icon-button:focus {
  color: #dd3629;
  transform: scale(1.05);
}
.employees-search > .icon-button path:last-of-type {
  fill: #ffffff;
}
.employees-search .employees-wrapper {
  display: flex;
  flex-direction: column;
  height: 23rem;
  border: 1px solid #ebeced;
  border-top: 0;
  border-radius: 0 0 0.8rem 0.8rem;
  overflow-y: auto;
}
.employees-search .employees-wrapper > button {
  display: flex;
  justify-content: space-between;
  min-height: 3.6rem;
  padding: 0.8rem 5.2rem 0.8rem 1rem;
  border: none;
  background-color: #ffffff;
  color: #47525a;
  font-size: 14px;
  line-height: 20px;
}
.employees-search .employees-wrapper > button:hover, .employees-search .employees-wrapper > button:focus {
  color: #4986a0;
  background-color: #f6f9fa;
  outline: transparent;
  cursor: pointer;
}
.employees-search .employees-wrapper .no-employees {
  font-weight: 700;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: center;
}
.employees-search .employees-wrapper > span {
  margin: 1.6rem;
}