@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes slide-in-right {
  from {
    right: -1rem;
    opacity: 0;
  }
  to {
    right: 0rem;
    opacity: 1;
  }
}
.team-member-info {
  overflow: hidden;
  position: relative;
  text-align: left;
  background-color: #f6f9fa;
  border-radius: 0.4rem;
  border: 1px solid #edf3f6;
  height: 100%;
  width: 18.4rem;
}
.team-member-info .name {
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0 1.5rem;
  padding-top: 1rem;
}
.team-member-info .shift {
  margin-left: 1.5rem;
  padding: 0;
  font-size: 1.2rem;
  text-align: left;
  line-height: 1.6rem;
  border: none;
  color: #999fa3;
  background-color: #f6f9fa;
}
.team-member-info .shift > span:last-of-type {
  display: block;
}
.team-member-info .shift:hover, .team-member-info .shift:focus {
  cursor: pointer;
  outline: transparent;
  color: #656c71;
}
.team-member-info:hover, .team-member-info:focus {
  border: 1px solid #4986a0;
  outline: transparent;
}
.team-member-info .delete-action-wrapper {
  display: none;
  position: absolute;
  height: 100%;
  right: 0.5rem;
  top: 0;
}
.team-member-info .delete-action-wrapper .icon-button {
  position: absolute;
  right: 0;
  top: calc(50% - 0.8rem);
  color: #e45f55;
}
.team-member-info:hover .delete-action-wrapper, .team-member-info:focus .delete-action-wrapper {
  display: block;
}
.team-member-info:hover .delete-action-wrapper .icon-button, .team-member-info:focus .delete-action-wrapper .icon-button {
  animation-name: slide-in-right;
  animation-duration: 0.2s;
  animation-timing-function: ease-in;
}
.team-member-info.shift-overlap {
  border-color: #e45f55;
}
.team-member-info.shift-overlap .shift > span:first-of-type {
  color: #e45f55;
  font-weight: 500;
}
.team-member-info.shift-overlap .overlap-icon {
  bottom: 0.5rem;
  right: 0.5rem;
  position: absolute;
  color: #e45f55;
}