$grey-bright: #f6f9fa;
$grey-light: #ebeced;
$grey: #999fa3;
$grey-dark: #47525a;

$blue-light: #edf3f6;
$blue-royal: #5175d1;
$blue: #4986a0;
$blue-dark: #333f48;
$cyan: #77a4b8;
$cyan-blue: #dee8ed;
$white-transparent: rgba(255, 255, 255, 0.1);
$white: #ffffff;

$red: #e45f55;
$green: #24c289;
$orange: #ed8f21;
$yellow: #ebc43a;

$primary: $blue;
$text-color: $grey-dark;

$shadow: rgba(73, 134, 160, 0.08);
$shade-accent: #ededed;
