.toast-container .toast {
  background-color: #4986a0;
  border-radius: 0.4rem;
  color: #ffffff;
  font-size: 1.4rem;
  font-family: Roboto, sans-serif;
  padding: 1.6rem;
  cursor: default;
  display: flex;
  align-items: center;
}
.toast-container .toast .icon-button {
  cursor: pointer;
  color: #ffffff;
  height: 2.4rem;
}
.toast-container .toast.Toastify__toast--error {
  background-color: #e45f55;
}
.toast-container .toast-progress {
  background: rgba(255, 255, 255, 0.1);
}

.App {
  padding-top: 6.4rem;
}