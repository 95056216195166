@import '../../_styles/colors';
@import '../../_styles/constants';

.grid-wrapper {
  overflow-x: scroll;
  height: 100%;
  position: relative;
  padding-top: 3rem;
}

.grid {
  border-top: 1px solid $grey-light;
  border-bottom: 1px solid $grey-light;
  border-right: 1px solid $grey-light;
  border-radius: $grid-border-radius;
  height: 100%;
  width: calc(#{$grid-tasks-width} + #{$grid-team-width});
  position: relative;

  &.is-resizing {
    .timeline-task,
    .timeline-task-group {
      display: none !important;
    }
  }

  &-content {
    display: flex;
    border-bottom-left-radius: $grid-border-radius;
    border-bottom-right-radius: $grid-border-radius;
    box-shadow: 0px 4px 8px $shadow;
    height: calc(100% - #{$time-indicator-height});

    .teams-column {
      position: sticky;
      left: 0;
      z-index: 6;

      overflow-y: scroll;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        display: none;
      }
      .team {
        width: $grid-team-width;
        background: $white;
        border-right: thin solid $grey-light;
        border-left: thin solid $grey-light;
      }
      .team-separator {
        height: 1px;
        border-bottom: thin solid $grey-light;
      }
    }

    .tasks-column {
      overflow-y: scroll;
      overflow-x: hidden;
      .tasks-content {
        position: relative;
        width: 100%;
      }
      &::-webkit-scrollbar {
        display: none;
      }

      .tasks-row-container {
        border-top: thin solid $grey-light;
        &:first-of-type {
          border-top: 0;
        }
        &:last-of-type {
          border-bottom: thin solid $grey-light;
        }
      }
      .task-group-row {
        position: relative;
        width: $grid-tasks-width;
        text-align: left;
      }
      .tasks-row {
        position: relative;
        width: $grid-tasks-width;
        text-align: left;
        background-color: $white;

        .not-working-block {
          position: absolute;
          background: repeating-linear-gradient(-45deg, $shade-accent, $shade-accent 1px, $white 1px, $white 8px);
        }
      }
    }
    .drag-over {
      .task-group-row,
      .tasks-row {
        background-color: transparentize($green, 0.9);
      }
    }
    .no-drop .tasks-row,
    .no-drop .task-group-row {
      background-color: $white;
    }
  }
}
