.sidebar-footer {
  flex: 0;
  display: flex;
  padding: 2.4rem 3.2rem;
  border-top: 1px solid #ebeced;
  justify-content: flex-end;
}
.sidebar-footer button,
.sidebar-footer .ui.button {
  margin-right: 1.4rem;
}
.sidebar-footer button:last-of-type,
.sidebar-footer .ui.button:last-of-type {
  margin-right: 0;
}