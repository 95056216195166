.main-menu {
  z-index: 10;
  background-color: #333f48;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 6.4rem;
  display: flex;
}
.main-menu aside {
  background-color: rgba(255, 255, 255, 0.1);
  height: 100%;
  padding: 1.2rem 2rem;
  margin-right: 1rem;
}
.main-menu a {
  position: relative;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0.5;
  padding: 2.2rem 2.4rem;
}
.main-menu a:hover {
  opacity: 0.8;
}
.main-menu a.active {
  opacity: 1;
}
.main-menu a.active:after {
  content: "";
  height: 2px;
  position: absolute;
  bottom: 1.4rem;
  left: 2.5rem;
  right: 2.5rem;
  background-color: #ffffff;
}