@import '../../_styles/colors';

.issue-item {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  padding: 1.6rem 2rem 1.6rem 3.2rem;
  border: none;
  outline: transparent;
  background-color: $white;
  cursor: pointer;

  &:hover {
    background-color: $grey-bright;
  }

  .attachment-preview {
    width: 7.2rem;
    min-width: 7.2rem;
    height: 7.2rem;
    min-height: 7.2rem;
    overflow: hidden;
    margin-right: 1.6rem;
    border-radius: 0.4rem;
    background-color: $blue-light;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  & > div {
    overflow: hidden;
  }

  h2 {
    margin-bottom: 0.4rem;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.2rem;
    text-align: left;
  }
  .created-at {
    position: absolute;
    right: 2rem;
    top: 1.6rem;
    font-weight: 500;
    font-size: 1.2rem;
    color: $grey;
  }

  .issue-reporter {
    margin-bottom: 0.75rem;
    text-align: left;

    &-info {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 2rem;
      color: $grey;

      .icon {
        margin-right: 0.8rem;
      }
    }
  }

  .description {
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: $grey;
  }

  .unread-indicator {
    position: absolute;
    left: 1.2rem;
    top: calc(3.6rem + 1.6rem - 0.4rem);
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background: $red;
  }
}
