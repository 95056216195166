.task-badge.accepted {
  background-color: #ebc43a;
  color: #47525a;
}
.task-badge.planned {
  background: repeating-linear-gradient(-45deg, #dbdddf, #dbdddf 1px, #ebeced 1px, #ebeced 8px);
  color: #47525a;
}
.task-badge.accepted.no-time, .task-badge.planned.no-time {
  background: #e45f55;
  color: #ffffff;
}
.task-badge.accepted.reduced-time, .task-badge.planned.reduced-time {
  background: #ed8f21;
  color: #ffffff;
}
.task-badge.arrived {
  background-color: #47525a;
  color: #ffffff;
}
.task-badge.ended {
  background-color: #24c289;
  color: #ffffff;
}
.task-badge.started {
  background-color: #5175d1;
  color: #ffffff;
}
.task-badge.unplanned {
  background-color: #f6f9fa;
  color: #47525a;
}
.task-badge.cancelled {
  background-color: #47525a;
  color: #ffffff;
}

.badge-divider {
  position: relative;
  padding-left: 3.2rem;
}
.badge-divider:before {
  left: 0;
  height: 1px;
  background-color: #ebeced;
  width: 100%;
  content: "";
  position: absolute;
  top: 50%;
}
.badge-divider .badge {
  position: relative;
}