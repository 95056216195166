@import '../../_styles/colors';

.task-issue-detail {
  .header-info {
    display: flex;
    justify-content: space-between;
    margin-top: 0.3rem;
    color: $grey;

    h2 {
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 2rem;
    }

    span {
      font-size: 1.4rem;
    }
  }

  .sidebar-content {
    border-top: 1px solid $grey-light;
    border-bottom: 1px solid $grey-light;
    padding: 2.4rem 2.6rem 3rem 2.6rem;

    .issue-detail-item {
      display: flex;
      font-size: 1.4rem;
      line-height: 2rem;

      .icon {
        color: $grey;
        margin-right: 1rem;
      }
    }

    .team-details {
      display: flex;
      margin-bottom: 2.4rem;

      .issue-detail-item:first-of-type {
        margin-right: 6rem;
      }
    }

    .attachments-wrapper {
      img {
        width: 100%;
        border-radius: 0.4rem;
        margin-top: 2.4rem;
      }

      .navigation {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1.6rem;

        span {
          margin: 0 1.6rem;
          font-size: 1.2rem;
          line-height: 1.4rem;
          color: $grey;
        }
      }
    }
  }
}
