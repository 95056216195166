.plan-task-modal .content .dropdown {
  margin: 0.8rem 0 1.2rem 0;
}
.plan-task-modal .row {
  display: flex;
  margin-top: 1.8rem;
}
.plan-task-modal .row > div {
  flex: 1;
}
.plan-task-modal .row > div:not(:first-child) {
  margin-left: 2.4rem;
}