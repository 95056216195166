.team-row {
  display: flex;
  border-top: 1px solid #ebeced;
  height: 9rem;
  width: 100%;
}
.team-row .team-info {
  min-width: 190px;
  height: calc(9rem - 0.1rem);
  border-right: 1px solid #ebeced;
}
.team-row:first-of-type {
  border-top: 1px solid #edf3f6;
}
.team-row .pda-user-wrapper {
  width: 20rem;
  padding: 0.8rem 0.8rem;
  height: 100%;
  border-right: 1px dashed #999fa3;
}
.team-row .other-members-wrapper {
  display: flex;
  flex-direction: row;
  padding: 0.8rem 0 0.8rem 0;
}
.team-row .other-members-wrapper > * {
  margin-left: 0.8rem;
}
.team-row:hover, .team-row:focus {
  border-top: 1px solid #4986a0;
}
.team-row:hover .name, .team-row:focus .name {
  color: #4986a0;
}
.team-row:hover + .team-row, .team-row:focus + .team-row {
  border-top: 1px solid #4986a0;
}