@import '../../_styles/constants';
@import '../../_styles/colors';

.timeline-task-group {
  position: absolute;
  height: $grid-task-group-height;
  background-color: $grey-bright;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  top: 0.5rem;
  border-radius: 0.4rem;
  cursor: pointer;
  overflow: hidden;

  &.overflow-left {
    padding-left: $task-overflow-padding;
  }

  .icon {
    color: $primary;
  }

  .title {
    padding: 0 0.4rem;
    font-weight: 500;
    white-space: nowrap;
  }

  .time {
    padding: 0 0.4rem;
    color: $grey;
    white-space: nowrap;
  }
}
