.change-shift-modal h1 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.3rem;
}
.change-shift-modal h2 {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #999fa3;
}
.change-shift-modal .shift-times-wrapper {
  display: flex;
  margin-bottom: 2.4rem;
}
.change-shift-modal .shift-times-wrapper .input-wrapper {
  width: 10.4rem;
}
.change-shift-modal .shift-times-wrapper .input-wrapper .ui.input {
  margin-right: 2.4rem;
  width: 8rem;
}
.change-shift-modal .error-message {
  margin-top: 1.6rem;
}