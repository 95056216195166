@import '../../_styles/colors';

.task-detail {
  .row {
    padding: 0 3.2rem;
    display: flex;
    margin-bottom: 3.2rem;
    .col {
      flex: 1;
    }
  }

  .divider {
    border-top: 1px solid $grey-light;
    margin-bottom: 3.5rem;
  }

  .badge-divider + .row {
    margin-top: 3.2rem;
  }

  .with-icon .col {
    display: flex;
    align-items: center;
    .icon {
      margin-right: 1.2rem;
      color: $grey;
    }
    &.teams {
      align-items: flex-start;
      margin-left: 3.6rem;

      .values {
        width: 100%;
      }

      label {
        color: $grey;
        font-size: 1.2rem;
        margin-top: 2rem;
      }

      .team {
        display: flex;
        justify-content: space-between;
        margin-top: 0.8rem;
        width: 100%;
        position: relative;

        &:first-of-type {
          margin-top: 0;
        }

        .value-light,
        .task-state {
          flex: 1;
        }

        .task-state {
          color: $grey;
          font-size: 1.2rem;
          font-weight: 500;
        }

        .icon-button {
          position: absolute;
          right: 0;

          .icon {
            color: $blue;
          }
        }

        .main-team-icon {
          position: absolute;
          left: -3.6rem;
          top: -0.3rem;
        }
      }
    }
    &.reduced-time,
    &.reduced-time .icon {
      color: $orange;
    }
    &.no-time,
    &.no-time .icon {
      color: $red;
    }
  }

  .flight-info .col {
    label {
      display: block;
      color: $grey;
      font-size: 1.2rem;
    }
  }

  .values {
    display: flex;
    flex-direction: column;
  }

  .unknown-value {
    color: $red;
  }

  .value {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .value-light {
    font-size: 1.4rem;
  }

  .sub-value {
    font-size: 1.2rem;
    color: $grey;
  }
  .other-teams {
    font-size: 1.4rem;
    padding-top: 0.4rem;
    display: block;
  }

  .issues {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.4rem;

    button {
      color: $red;
    }

    .unread-issues {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.5rem;
      text-transform: lowercase;
      color: $grey;
    }

    .empty-issues {
      font-weight: 400;
      font-style: italic;
      color: $grey;
    }
  }
}
