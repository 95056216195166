.task-issue-detail .header-info {
  display: flex;
  justify-content: space-between;
  margin-top: 0.3rem;
  color: #999fa3;
}
.task-issue-detail .header-info h2 {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2rem;
}
.task-issue-detail .header-info span {
  font-size: 1.4rem;
}
.task-issue-detail .sidebar-content {
  border-top: 1px solid #ebeced;
  border-bottom: 1px solid #ebeced;
  padding: 2.4rem 2.6rem 3rem 2.6rem;
}
.task-issue-detail .sidebar-content .issue-detail-item {
  display: flex;
  font-size: 1.4rem;
  line-height: 2rem;
}
.task-issue-detail .sidebar-content .issue-detail-item .icon {
  color: #999fa3;
  margin-right: 1rem;
}
.task-issue-detail .sidebar-content .team-details {
  display: flex;
  margin-bottom: 2.4rem;
}
.task-issue-detail .sidebar-content .team-details .issue-detail-item:first-of-type {
  margin-right: 6rem;
}
.task-issue-detail .sidebar-content .attachments-wrapper img {
  width: 100%;
  border-radius: 0.4rem;
  margin-top: 2.4rem;
}
.task-issue-detail .sidebar-content .attachments-wrapper .navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.6rem;
}
.task-issue-detail .sidebar-content .attachments-wrapper .navigation span {
  margin: 0 1.6rem;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #999fa3;
}