@import '../../_styles/colors';
@import '../../_styles/constants';

$icon-height: 2.4rem;
$label-bottom-margin: 0.5rem;

.input-wrapper {
  width: 100%;

  label:not(:last-child) {
    display: flex;
    align-items: center;
    height: $icon-height;
    margin-bottom: $label-bottom-margin;
    font-size: 1.4rem;
    color: $grey;

    :not(:first-child) {
      margin-left: 0.75rem;
    }

    i,
    svg {
      width: $icon-height;
      height: $icon-height;
    }
  }

  &.error {
    .ui.checkbox label {
      color: $red;
    }
  }

  .error-message {
    padding-top: 1rem;
  }
}

.input-date-wrapper {
  position: relative;
  .ui.input > input {
    padding-right: 4rem;
  }

  .datepicker-icon {
    position: absolute;
    right: 1rem;
    top: 0;
    height: $input-height;
    display: flex;
    align-items: center;

    & > * {
      color: $blue;
    }
  }

  &.has-label {
    .datepicker-icon {
      top: calc(#{$icon-height} + #{$label-bottom-margin});
    }
  }
}
