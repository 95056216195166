@import '../../_styles/colors';

.current-time {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 5;
  width: 0.1rem;
  background: $red;
}
