.input-wrapper {
  width: 100%;
}
.input-wrapper label:not(:last-child) {
  display: flex;
  align-items: center;
  height: 2.4rem;
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
  color: #999fa3;
}
.input-wrapper label:not(:last-child) :not(:first-child) {
  margin-left: 0.75rem;
}
.input-wrapper label:not(:last-child) i,
.input-wrapper label:not(:last-child) svg {
  width: 2.4rem;
  height: 2.4rem;
}
.input-wrapper.error .ui.checkbox label {
  color: #e45f55;
}
.input-wrapper .error-message {
  padding-top: 1rem;
}

.input-date-wrapper {
  position: relative;
}
.input-date-wrapper .ui.input > input {
  padding-right: 4rem;
}
.input-date-wrapper .datepicker-icon {
  position: absolute;
  right: 1rem;
  top: 0;
  height: 4rem;
  display: flex;
  align-items: center;
}
.input-date-wrapper .datepicker-icon > * {
  color: #4986a0;
}
.input-date-wrapper.has-label .datepicker-icon {
  top: calc(2.4rem + 0.5rem);
}