@import './_styles/reset';
@import './_styles/colors';
@import './_styles/constants';

html,
body {
  margin: 0;
  font-size: 10px;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
}

button {
  color: $text-color;
  font-family: $font-family;
}

h1 {
  font-size: 3rem;
  font-weight: 700;
}

h2 {
  font-size: 2.4rem;
  font-weight: 500;
}

h3 {
  font-size: 2rem;
  font-weight: 700;
}

h4 {
  font-size: 1.8rem;
  font-weight: 500;
}

h5 {
  font-size: 1.6rem;
  font-weight: 700;
}

h6 {
  font-size: 1.4rem;
  font-weight: 500;
}

body {
  font-size: 1.6rem;
}
