.planning-wrapper {
  padding: 1.2rem 20px;
  height: calc(100vh - 6.4rem);
}

.planning-button-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.planning-button-bar > div:first-child, .planning-button-bar > div:last-child {
  flex: 1;
}
.planning-button-bar > div:last-child {
  display: flex;
  justify-content: flex-end;
}
.planning-button-bar > div:last-child > :not(:first-child) {
  margin-left: 1rem;
}