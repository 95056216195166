@import '../../../_styles/colors';

.add-team-member-button {
  background-color: white;
  color: $blue;
  border: 1px dashed $blue;
  box-sizing: border-box;
  border-radius: 0.4rem;
  width: 18.4rem;
  height: 100%;
  font-weight: 500;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    margin-left: 0.8rem;
  }

  &:focus,
  &:hover {
    border: 1px dashed darken($blue, 10);
    color: darken($blue, 10);
    cursor: pointer;
    outline: transparent;

    i {
      transform: scale(1.05);
    }
  }
}

.add-team-member-modal {
  .error-message {
    margin-top: 1.6rem;
  }
}
