@import '../../_styles/colors';
@import '../../_styles/constants';

@mixin five-minute-mark {
  content: '';
  position: absolute;
  bottom: 0;
  height: $time-indicator-ticks-height;
  width: 0.1rem;
  background-color: $grey;
}

.time-indicator {
  display: flex;
  position: sticky;
  top: 0;
  border-top-left-radius: $grid-border-radius;
  border-top-right-radius: $grid-border-radius;
  background-color: $grey-bright;

  .title-wrapper {
    display: flex;
    align-items: center;
    position: sticky;
    left: 0;
    z-index: 6;
    min-width: $grid-team-width;
    height: $time-indicator-height;
    border-right: 1px solid $grey-light;
    border-left: 1px solid $grey-light;
    background-color: $grey-bright;

    span {
      padding-left: 1.6rem;
      font-weight: 700;
      font-size: 1.2rem;
      color: $primary;
    }

    &:after {
      content: '';
      width: calc(#{$grid-team-width} - 1px); // subtract the border width
      height: $time-indicator-ticks-height;
      position: absolute;
      bottom: 0;
      box-sizing: border-box;
      border-right: 1px solid $grey-light;
      background-color: $blue-light;
    }
  }

  .timeframe-wrapper {
    display: flex;
    position: relative;
    width: $grid-tasks-width;
    border-right: 1px solid $grey-light;

    &::-webkit-scrollbar {
      display: none;
    }

    .timeframe {
      display: flex;
      align-items: center;
      position: relative;
      width: $quarter-width;
      height: $time-indicator-height;

      span {
        margin-left: -1.6rem;
        font-size: 1.2rem;
        color: $grey;
      }
      &-ticks {
        position: absolute;
        bottom: 0;
        height: $time-indicator-ticks-height;
        width: 100%;
        border-right: 1px solid $grey;
        background-color: $blue-light;

        &:before {
          @include five-minute-mark;
          left: 33.3%;
        }

        &:after {
          @include five-minute-mark;
          left: 66.6%;
        }
      }

      &:last-of-type .timeframe-ticks {
        border-right: 0;
      }
    }
  }
}
