@import '../../_styles/colors';
@import '../../_styles/constants';

$team-height: 9rem;
$pda-column-width: 20rem;

.team-row {
  display: flex;
  border-top: 1px solid $grey-light;
  height: $team-height;
  width: 100%;

  .team-info {
    min-width: $grid-team-width;
    height: calc(#{$team-height} - 0.1rem /* Team-height minus the border */);
    border-right: 1px solid $grey-light;
  }

  &:first-of-type {
    border-top: 1px solid $blue-light;
  }

  .pda-user-wrapper {
    width: $pda-column-width;
    padding: 0.8rem 0.8rem;
    height: 100%;
    border-right: 1px dashed $grey;
  }
  .other-members-wrapper {
    display: flex;
    flex-direction: row;
    padding: 0.8rem 0 0.8rem 0;
    > * {
      margin-left: 0.8rem;
    }
  }

  &:hover,
  &:focus {
    border-top: 1px solid $blue;

    .name {
      color: $blue;
    }

    & + .team-row {
      border-top: 1px solid $blue;
    }
  }
}
