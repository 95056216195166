.task-detail .row {
  padding: 0 3.2rem;
  display: flex;
  margin-bottom: 3.2rem;
}
.task-detail .row .col {
  flex: 1;
}
.task-detail .divider {
  border-top: 1px solid #ebeced;
  margin-bottom: 3.5rem;
}
.task-detail .badge-divider + .row {
  margin-top: 3.2rem;
}
.task-detail .with-icon .col {
  display: flex;
  align-items: center;
}
.task-detail .with-icon .col .icon {
  margin-right: 1.2rem;
  color: #999fa3;
}
.task-detail .with-icon .col.teams {
  align-items: flex-start;
  margin-left: 3.6rem;
}
.task-detail .with-icon .col.teams .values {
  width: 100%;
}
.task-detail .with-icon .col.teams label {
  color: #999fa3;
  font-size: 1.2rem;
  margin-top: 2rem;
}
.task-detail .with-icon .col.teams .team {
  display: flex;
  justify-content: space-between;
  margin-top: 0.8rem;
  width: 100%;
  position: relative;
}
.task-detail .with-icon .col.teams .team:first-of-type {
  margin-top: 0;
}
.task-detail .with-icon .col.teams .team .value-light,
.task-detail .with-icon .col.teams .team .task-state {
  flex: 1;
}
.task-detail .with-icon .col.teams .team .task-state {
  color: #999fa3;
  font-size: 1.2rem;
  font-weight: 500;
}
.task-detail .with-icon .col.teams .team .icon-button {
  position: absolute;
  right: 0;
}
.task-detail .with-icon .col.teams .team .icon-button .icon {
  color: #4986a0;
}
.task-detail .with-icon .col.teams .team .main-team-icon {
  position: absolute;
  left: -3.6rem;
  top: -0.3rem;
}
.task-detail .with-icon .col.reduced-time, .task-detail .with-icon .col.reduced-time .icon {
  color: #ed8f21;
}
.task-detail .with-icon .col.no-time, .task-detail .with-icon .col.no-time .icon {
  color: #e45f55;
}
.task-detail .flight-info .col label {
  display: block;
  color: #999fa3;
  font-size: 1.2rem;
}
.task-detail .values {
  display: flex;
  flex-direction: column;
}
.task-detail .unknown-value {
  color: #e45f55;
}
.task-detail .value {
  font-size: 1.6rem;
  font-weight: 500;
}
.task-detail .value-light {
  font-size: 1.4rem;
}
.task-detail .sub-value {
  font-size: 1.2rem;
  color: #999fa3;
}
.task-detail .other-teams {
  font-size: 1.4rem;
  padding-top: 0.4rem;
  display: block;
}
.task-detail .issues {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.4rem;
}
.task-detail .issues button {
  color: #e45f55;
}
.task-detail .issues .unread-issues {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.5rem;
  text-transform: lowercase;
  color: #999fa3;
}
.task-detail .issues .empty-issues {
  font-weight: 400;
  font-style: italic;
  color: #999fa3;
}