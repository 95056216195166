$font-family: 'Roboto', sans-serif;

$navbar-height: 6.4rem;
$input-height: 4rem;

$time-indicator-height: 4rem;
$time-indicator-ticks-height: 0.4rem;

$quarter-width-px: 112;
$quarter-width: 11.2rem;

$grid-border-radius: 0.2rem;
$grid-margin: 1.2rem;
$grid-tasks-width: $quarter-width * 4 * 24; // (4 quarters in 1 hour -> 4 * 24 for a day)
$grid-team-width-value: 190;
$grid-team-width: #{$grid-team-width-value}px;
$grid-gradient-width: 4rem;

$grid-task-group-row-height: 3.4; // export value
$grid-task-group-height: 2.8rem; // Same as above value, but without padding

$task-overflow-value: 20;
$task-overflow-padding: calc(#{$task-overflow-value}px + 4px);

$planning-wrapper-padding-vertical: 1.2rem;
$planning-wrapper-padding-horizontal-value: 20;
$planning-wrapper-padding-horizontal: #{$task-overflow-value}px;
$planning-toolbar-height: 4rem;

$timeline-arrow-width-value: 26;
$timeline-arrow-width: #{$timeline-arrow-width-value}px;
$timeline-arrow-height-value: 18;
$timeline-arrow-height: #{$timeline-arrow-height-value}px;
