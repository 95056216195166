.sidebar-header {
  padding: 4rem 3.2rem 2.4rem;
}
.sidebar-header > div {
  flex: 0;
  display: flex;
  align-items: center;
}
.sidebar-header > div h1 {
  display: inline-block;
  margin-right: 0.8rem;
  font-size: 2rem;
  line-height: 2.3rem;
}
.sidebar-header > div .badge {
  margin-right: 0.6rem;
}
.sidebar-header-buttons.left ~ .sidebar-header {
  padding: 5.1rem 3.2rem 2.4rem;
}