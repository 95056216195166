.time-line-arrow {
  position: absolute;
  height: 18px;
  width: 26px;
  background-color: #e45f55;
  color: white;
  z-index: 8;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: -26px;
  margin-left: calc(-26px / 2);
  transform: rotate(-90deg);
  transition: transform 0.1s ease-out;
  cursor: pointer;
}
.time-line-arrow:before {
  content: "";
  position: absolute;
  left: -0.4rem;
  width: 0;
  height: 0;
  border-top: 0.4rem solid transparent;
  border-bottom: 0.4rem solid transparent;
  border-right: 0.4rem solid #e45f55;
}
.time-line-arrow.fixed {
  position: fixed;
}
.time-line-arrow.hidden-arrow {
  opacity: 0;
  pointer-events: none;
}
.time-line-arrow.left {
  transform: rotate(0deg) translate(calc(26px / 2 - 0.4rem), calc(26px - 18px));
  margin-left: 0.4rem;
}
.time-line-arrow.right {
  transform: rotate(-180deg) translateY(calc(18px - 26px));
  margin-left: 0.4rem;
}
.time-line-arrow.right > span {
  transform: rotate(180deg);
}