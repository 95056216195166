.timeline-task-group {
  position: absolute;
  height: 2.8rem;
  background-color: #f6f9fa;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  top: 0.5rem;
  border-radius: 0.4rem;
  cursor: pointer;
  overflow: hidden;
}
.timeline-task-group.overflow-left {
  padding-left: calc(20px + 4px);
}
.timeline-task-group .icon {
  color: #4986a0;
}
.timeline-task-group .title {
  padding: 0 0.4rem;
  font-weight: 500;
  white-space: nowrap;
}
.timeline-task-group .time {
  padding: 0 0.4rem;
  color: #999fa3;
  white-space: nowrap;
}