@import '../../_styles/colors';
@import '../../_styles/constants';

$tip-size: 0.4rem;

.time-line-arrow {
  position: absolute;
  height: $timeline-arrow-height;
  width: $timeline-arrow-width;
  background-color: $red;
  color: white;
  z-index: 8;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: -$timeline-arrow-width;
  margin-left: calc(-#{$timeline-arrow-width} / 2);
  transform: rotate(-90deg);
  transition: transform 0.1s ease-out;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    left: -$tip-size;
    width: 0;
    height: 0;
    border-top: $tip-size solid transparent;
    border-bottom: $tip-size solid transparent;
    border-right: $tip-size solid $red;
  }
  &.fixed {
    position: fixed;
  }
  &.hidden-arrow {
    opacity: 0;
    pointer-events: none;
  }

  &.left {
    transform: rotate(0deg)
      translate(calc(#{$timeline-arrow-width} / 2 - #{$tip-size}), calc(#{$timeline-arrow-width} - #{$timeline-arrow-height}));
    margin-left: $tip-size;
  }
  &.right {
    transform: rotate(-180deg) translateY(calc(#{$timeline-arrow-height} - #{$timeline-arrow-width}));
    margin-left: $tip-size;
    & > span {
      transform: rotate(180deg);
    }
  }
}
