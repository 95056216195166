@import '../_styles/constants';

.planning-wrapper {
  padding: $planning-wrapper-padding-vertical $planning-wrapper-padding-horizontal;
  height: calc(100vh - #{$navbar-height});
}

.planning-button-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    &:first-child,
    &:last-child {
      flex: 1;
    }
    &:last-child {
      display: flex;
      justify-content: flex-end;
      > :not(:first-child) {
        margin-left: 1rem;
      }
    }
  }
}
