.time-indicator {
  display: flex;
  position: sticky;
  top: 0;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  background-color: #f6f9fa;
}
.time-indicator .title-wrapper {
  display: flex;
  align-items: center;
  position: sticky;
  left: 0;
  z-index: 6;
  min-width: 190px;
  height: 4rem;
  border-right: 1px solid #ebeced;
  border-left: 1px solid #ebeced;
  background-color: #f6f9fa;
}
.time-indicator .title-wrapper span {
  padding-left: 1.6rem;
  font-weight: 700;
  font-size: 1.2rem;
  color: #4986a0;
}
.time-indicator .title-wrapper:after {
  content: "";
  width: calc(190px - 1px);
  height: 0.4rem;
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  border-right: 1px solid #ebeced;
  background-color: #edf3f6;
}
.time-indicator .timeframe-wrapper {
  display: flex;
  position: relative;
  width: 1075.2rem;
  border-right: 1px solid #ebeced;
}
.time-indicator .timeframe-wrapper::-webkit-scrollbar {
  display: none;
}
.time-indicator .timeframe-wrapper .timeframe {
  display: flex;
  align-items: center;
  position: relative;
  width: 11.2rem;
  height: 4rem;
}
.time-indicator .timeframe-wrapper .timeframe span {
  margin-left: -1.6rem;
  font-size: 1.2rem;
  color: #999fa3;
}
.time-indicator .timeframe-wrapper .timeframe-ticks {
  position: absolute;
  bottom: 0;
  height: 0.4rem;
  width: 100%;
  border-right: 1px solid #999fa3;
  background-color: #edf3f6;
}
.time-indicator .timeframe-wrapper .timeframe-ticks:before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 0.4rem;
  width: 0.1rem;
  background-color: #999fa3;
  left: 33.3%;
}
.time-indicator .timeframe-wrapper .timeframe-ticks:after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 0.4rem;
  width: 0.1rem;
  background-color: #999fa3;
  left: 66.6%;
}
.time-indicator .timeframe-wrapper .timeframe:last-of-type .timeframe-ticks {
  border-right: 0;
}