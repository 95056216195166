.task-issues .sidebar-content {
  border-top: 1px solid #ebeced;
  border-bottom: 1px solid #ebeced;
}
.task-issues .sidebar-content > .issue-item:after {
  content: "";
  height: 0.1rem;
  width: 100%;
  bottom: 0;
  left: 0;
  margin-left: 2rem;
  position: absolute;
  background: #ebeced;
}
.task-issues .sidebar-content > .issue-item:last-of-type:after {
  content: none;
}