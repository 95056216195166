@import '../../_styles/colors';
@import '../../_styles/constants';

.panel-container {
  display: flex;
  height: 100%;
  flex-direction: column;

  .resizer-container {
    width: calc(#{$grid-tasks-width} + #{$grid-team-width});
    height: 1.2rem;
    margin: 0.5rem 0;
    z-index: 9;
  }

  .resizer {
    position: sticky;
    cursor: row-resize;
    width: 100vw;
    user-select: none;
    left: 0;
    height: 100%;
  }

  .resizer::after,
  .resizer::before {
    content: '';
    border-top: 1px solid $grey;
    position: absolute;
    left: 50%;
    transform: translateX(-100%);
    bottom: 0;
    display: inline-block;
    width: 2rem;
    margin: 0.3rem 0;
  }
  .resizer::before {
    top: 0;
  }
}
