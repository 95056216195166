.button-group {
  display: inline-block;
  border-radius: 8px;
  box-shadow: 0px 8px 15px rgba(73, 134, 160, 0.08);
}
.button-group button {
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
  color: #4986a0;
  background-color: #ffffff;
  width: 12rem;
  height: 4rem;
  border: none;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.2rem;
  text-align: center;
  border-radius: 8px;
}
.button-group button:hover, .button-group button:focus {
  outline: transparent;
  color: #39697d;
}
.button-group button.negative {
  color: #e45f55;
}

button.selected {
  background-color: #4986a0;
  color: #ffffff;
}
button.selected:hover, button.selected:focus {
  color: #ffffff;
  background-color: #39697d;
}