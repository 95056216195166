@import './colors';

.toast-container {
  .toast {
    background-color: $primary;
    border-radius: 0.4rem;
    color: $white;
    font-size: 1.4rem;
    font-family: Roboto, sans-serif;
    padding: 1.6rem;
    cursor: default;
    display: flex;
    align-items: center;

    .icon-button {
      cursor: pointer;
      color: $white;
      height: 2.4rem;
    }
    &.Toastify__toast--error {
      background-color: $red;
    }
  }

  .toast-progress {
    background: $white-transparent;
  }
}
